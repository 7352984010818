var d = document
function $(id) {
  return d.getElementById(id)
}


var button = $('header__menu-button')
if (button) {
  button.addEventListener('click', function(event) {
    this.classList.toggle('header__menu-button--active')
    $('header__menu-mobile').classList.toggle('header__menu-mobile--open')
  })
}

var footerSelect = d.getElementsByClassName('footer__select')

for (var i = 0; i < footerSelect.length; i++) {
  footerSelect[i].onclick = function(event) {
    this.classList.toggle('footer__select--active')
  }
}
